// voice
export const VoiceSvgComponent = () => (
  <svg t="1636029116942" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6945">
    <path d="M727.013 511.999c0 172.83-74.72 329.054-194.914 441.249l42.145 70.75c1.535-1.44 3.105-2.75 4.64-4.19 294.814-280.449 294.814-735.168 0-1015.615-1.565-1.44-3.135-2.785-4.64-4.192l-42.175 70.752c120.224 112.159 194.944 268.381 194.944 441.246zM223.974 660.188l40.29 68.93c60.06-54.88 97.63-131.84 97.63-217.152 0-85.28-37.57-162.207-97.63-217.087l-40.29 68.927c41.47 37.12 67.49 89.727 67.49 148.16 0 58.462-26.02 111.072-67.49 148.222z m151.585-440.604c80.225 74.08 130.145 177.662 130.145 292.382 0 114.752-49.92 218.367-130.145 292.447l45.345 73.76c1.535-1.405 3.165-2.75 4.67-4.19 101.63-96.705 157.6-225.279 157.6-361.984 0-136.735-55.97-265.312-157.6-361.984-1.505-1.44-3.135-2.75-4.64-4.19l-45.375 73.759z" p-id="6946" fill="#ffffff"></path>
  </svg>
);
// keyboard
export const KeyboardSvgComponent = () => (
  <svg t="1636101420785" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8582">
    <path d="M928 192H96c-17.6 0-32 14.4-32 32v576c0 17.6 14.4 32 32 32h832c17.6 0 32-14.4 32-32V224c0-17.6-14.4-32-32-32z m-32 576H128V256h768v512zM320 384H192v-64h128v64z m448 192V448h64v192H640v-64h128z m-192 64H192v-64h384v64zM448 384h-64v-64h64v64z m128 0h-64v-64h64v64z m128 0h-64v-64h64v64z m128 0h-64v-64h64v64zM256 512h-64v-64h64v64z m64-64h64v64h-64v-64z m128 0h64v64h-64v-64z m128 0h64v64h-64v-64z" p-id="8583" fill="#ffffff"></path>
  </svg>
);
// img
export const ImgSvgComponent = () => (
  <svg t="1636026783675" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3994">
    <path d="M918.457 862.043H105.543c-20.435 0-37-16.565-37-37V198.957c0-20.435 16.565-37 37-37h812.913c20.435 0 37 16.565 37 37v626.087c0.001 20.434-16.565 36.999-36.999 36.999z m-775.914-74h738.913V235.957H142.543v552.086z" p-id="3995" fill="#ffffff"></path>
    <path d="M155.696 826.143l-55.391-49.069L316.609 532.9c12.337-13.926 33.096-16.526 48.486-6.072l95.808 65.08 182.756-233.7a36.996 36.996 0 0 1 27.779-14.182 37.005 37.005 0 0 1 28.751 12.093L945.84 626.466l-54.768 49.765-216.147-237.879L497.88 664.749c-11.996 15.339-33.828 18.756-49.937 7.814l-97.517-66.241-194.73 219.821z" p-id="3996" fill="#ffffff"></path>
    <path d="M331.174 373m-55 0a55 55 0 1 0 110 0 55 55 0 1 0-110 0Z" p-id="3997" fill="#ffffff"></path>
  </svg>
);
// send
export const SendSvgComponent = () => (
  <svg t="1636028582514" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2418">
    <path d="M512 927.8464a32 32 0 0 1-32-31.8464V203.3664a32 32 0 0 1 64 0V896a32 32 0 0 1-32 31.8464z" fill="#ffffff" p-id="2419"></path>
    <path d="M766.464 458.8032a31.8976 31.8976 0 0 1-22.6304-9.3696L512 217.6 280.1664 449.4336a32 32 0 1 1-45.2608-45.2608L512 127.0784l277.0944 277.0944a32 32 0 0 1-22.6304 54.6304z" fill="#ffffff" p-id="2420"></path>
  </svg>
);
// music
export const MusicSvgComponent = () => (
  <svg t="1638281003403" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6818">
    <path d="M875.52 433.152q-7.168-1.024-12.8-10.24t-8.704-33.792q-5.12-39.936-26.112-58.88t-65.024-27.136q-46.08-9.216-81.408-37.376t-58.88-52.736q-22.528-21.504-34.816-15.36t-12.288 22.528l0 44.032 0 96.256q0 57.344-0.512 123.904t-0.512 125.952l0 104.448 0 58.368q1.024 24.576-7.68 54.784t-32.768 56.832-64 45.568-99.328 22.016q-60.416 3.072-109.056-21.504t-75.264-61.952-26.112-81.92 38.4-83.456 81.92-54.272 84.992-16.896 73.216 5.632 47.616 13.312l0-289.792q0-120.832 1.024-272.384 0-29.696 15.36-48.64t40.96-22.016q21.504-3.072 35.328 8.704t28.16 32.768 35.328 47.616 56.832 52.224q30.72 23.552 53.76 33.792t43.008 18.944 39.424 20.992 43.008 39.936q23.552 26.624 28.672 55.296t0.512 52.224-14.848 38.4-17.408 13.824z" p-id="6819" fill="#2c2c2c"></path>
  </svg>
);